import "./App.scss";
import Header from "./components/Header";
import Home from "./components/Home";
import AboutUs from "./components/About";
import Services from "./components/Services";
import Resume from "./components/Resume";
import Portfolio from "./components/Portfolio";
import Testimonials from "./components/Testimonials";
import Contact from "./components/Contact";
// import Footer from "./components/Footer";
import { useEffect, useState } from "react";
import ClassicHeader from "./components/ClassicHeader";
import { commonConfig } from "./config/commonConfig";
import TermsAndConditions from "./components/TermsAndConditions";
import Disclaimer from "./components/Disclaimer";
import PreLoader from "./components/Preloader";
import { Tooltip } from "./components/Tooltip";
import { database } from './config/firebase';
import { Analytics } from "@vercel/analytics/react"

function App() {
  const classicHeader = commonConfig.classicHeader;
  const darkTheme = commonConfig.darkTheme;

  const [dataAbout, setDataAbout] = useState(null);
  const [dataServices, setDataServices] = useState(null);
  const [dataWorks, setDataWorks] = useState(null);
  const [dataEducations, setDataEducations] = useState(null);
  const [dataSkills, setDataSkills] = useState(null);
  const [dataProtfolio, setDataProtfolio] = useState(null);
  const [dataReviews, setDataReviews] = useState(null);
  const [dataCategories, setDataCategories] = useState(null);

  const handleNavClick = (section) => {
    document.getElementById(section).scrollIntoView({ behavior: "smooth" });
  };

  const [scrollTopVisible, setScrollTopVisible] = useState(false);
  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const snapshot = await database.ref('about').once('value');
      setDataAbout(snapshot.val());
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const snapshot = await database.ref('services').once('value');
      const data = snapshot.val()
      const servicesArray = [];

      for (let key in data) {
        const dataInner = { id: key, ...data[key] };
        servicesArray.push(dataInner);
      };
      setDataServices(servicesArray);
    };

    fetchData();
  }, []);

  // Education Data/////////////////////////////////
  useEffect(() => {
    const fetchData = async () => {
      const snapshot = await database.ref('educations').once('value');
      const data = snapshot.val()
      const educationsArray = [];

      for (let key in data) {
        const dataInner = { id: key, ...data[key] };
        educationsArray.push(dataInner);
      };
      setDataEducations(educationsArray);
    };

    fetchData();
  }, []);
  // Works Data/////////////////////////////////
  useEffect(() => {
    const fetchData = async () => {
      const snapshot = await database.ref('works').once('value');
      const data = snapshot.val()
      const worksArray = [];

      for (let key in data) {
        const dataInner = { id: key, ...data[key] };
        worksArray.push(dataInner);
      };
      setDataWorks(worksArray);
    };

    fetchData();
  }, []);

  // Skills Data/////////////////////////////////
  useEffect(() => {
    const fetchData = async () => {
      const snapshot = await database.ref('skills').once('value');
      const data = snapshot.val()
      const skillsArray = [];

      for (let key in data) {
        const dataInner = { id: key, ...data[key] };
        skillsArray.push(dataInner);
      };
      setDataSkills(skillsArray);
    };

    fetchData();
  }, []);

  // categories Data/////////////////////////////////
  useEffect(() => {
    const fetchData = async () => {
      const snapshot = await database.ref('categories').once('value');
      const data = snapshot.val()
      const categoriesArray = [];

      for (let key in data) {
        const dataInner = { id: key, ...data[key] };
        categoriesArray.push(dataInner);
      };
      setDataCategories(categoriesArray);
    };

    fetchData();
  }, []);


  // Protfolio Data/////////////////////////////////
  useEffect(() => {
    const fetchData = async () => {
      const snapshot = await database.ref('protfolios').once('value');
      const data = snapshot.val()
      const skillsArray = [];

      for (let key in data) {
        const dataInner = { id: key, ...data[key] };
        skillsArray.push(dataInner);
      };
      setDataProtfolio(skillsArray);
    };

    fetchData();
  }, []);


  // Testmonials Data/////////////////////////////////
  useEffect(() => {
    const fetchData = async () => {
      const snapshot = await database.ref('testimonials').once('value');
      const data = snapshot.val()
      const reviewsArray = [];

      for (let key in data) {
        const dataInner = { id: key, ...data[key] };
        reviewsArray.push(dataInner);
      };
      setDataReviews(reviewsArray);
    };

    fetchData();
  }, []);

  // useEffect(() => {
  // console.log("dataAbout : ", dataAbout);
  // console.log("dataServices : ", dataServices);
  // console.log("dataWorks : ", dataWorks);
  // console.log("dataEducations : ", dataEducations);
  // console.log("dataSkills : ", dataSkills);
  // console.log("dataProtfolio : ", dataProtfolio);
  // console.log("dataReviews : ", dataReviews);
  // }, [dataReviews, dataAbout, dataServices, dataWorks, dataEducations, dataSkills, dataProtfolio])



  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      setisLoading(false);
    }, 1000);
    return () => {
      clearTimeout(loadingTimeout);
    };
  }, []);


  const checkScrollTop = () => {
    let scrollTopBtn = document.getElementById("back-to-top");

    if (scrollTopBtn) {
      if (
        document.body.scrollTop > 400 ||
        document.documentElement.scrollTop > 400
      ) {
        setScrollTopVisible(true);
      } else {
        setScrollTopVisible(false);
      }
    }
  };

  if (typeof window !== "undefined") {
    window.addEventListener("scroll", checkScrollTop);
  }

  return (
    <>
      <div
        style={{ position: "relative" }}
        className={classicHeader ? "" : "side-header"}
      >
        {isLoading && <PreLoader></PreLoader>}

        <div id="main-wrapper">
          {classicHeader ? (
            <ClassicHeader handleNavClick={handleNavClick}></ClassicHeader>
          ) : (
            (dataAbout && Object.keys(dataAbout).length !== 0) &&
            <Header handleNavClick={handleNavClick} dataAbout={dataAbout}></Header>
          )}

          <div id="content" role="main">
            {dataAbout && (<Home
              classicHeader={classicHeader}
              darkTheme={darkTheme}
              handleNavClick={handleNavClick}
              dataAbout={dataAbout}
            ></Home>
            )}
            {dataAbout && (<AboutUs
              classicHeader={classicHeader}
              darkTheme={darkTheme}
              dataAbout={dataAbout}
            ></AboutUs>
            )}
            {dataServices && (<Services
              classicHeader={classicHeader}
              darkTheme={darkTheme}
              services={dataServices}
            ></Services>)}

            {dataEducations && dataWorks && dataSkills && (<Resume
              classicHeader={classicHeader}
              darkTheme={darkTheme}
              educationDetails={dataEducations}
              experienceDetails={dataWorks}
              skills={dataSkills}
              dataAbout={dataAbout}
            ></Resume>)}
            {dataProtfolio && dataCategories && (<Portfolio
              classicHeader={classicHeader}
              darkTheme={darkTheme}
              projectsData={dataProtfolio}
              filters={dataCategories}
            ></Portfolio>
            )}

            {dataReviews && (<Testimonials
              classicHeader={classicHeader}
              darkTheme={darkTheme}
              reviews={dataReviews}
            ></Testimonials>)}

            {dataAbout && (<Contact
              classicHeader={classicHeader}
              darkTheme={darkTheme}
              dataAbout={dataAbout}
            ></Contact>)}
          </div>
          {/*<Footer
            classicHeader={classicHeader}
            darkTheme={darkTheme}
            handleNavClick={handleNavClick}
            ></Footer>*/}
        </div>
        {/* back to top */}
        <Tooltip text="Back to Top" placement="left">
          <span
            id="back-to-top"
            className="rounded-circle"
            style={{ display: scrollTopVisible ? "inline" : "none" }}
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            <i className="fa fa-chevron-up"></i>
          </span>
        </Tooltip>

        <TermsAndConditions darkTheme={darkTheme}></TermsAndConditions>
        <Disclaimer darkTheme={darkTheme}></Disclaimer>
        <Analytics />
      </div>
    </>
  );
}

export default App;
