import firebase from 'firebase/compat/app';
import 'firebase/compat/database';

const firebaseConfig = {
    databaseURL: "https://protfolio-bc5c4-default-rtdb.asia-southeast1.firebasedatabase.app/",
    apiKey: "AIzaSyDOKItPE7XrzAffJp8j907k6ZLUeJvrtFg",
    authDomain: "protfolio-bc5c4.firebaseapp.com",
    projectId: "protfolio-bc5c4",
    storageBucket: "protfolio-bc5c4.appspot.com",
    messagingSenderId: "209479205552",
    appId: "1:209479205552:web:bc7cb4b7b7d91048597175",
    measurementId: "G-GW4DGX29PC"
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const database = firebase.database();

export { firebase, database };
